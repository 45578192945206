<template>
    <v-dialog
        ref="dialog"
        v-model="showDialog"
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="FormattedDate"
            :label="placeholder"
            readonly
            flat
            :solo-inverted="inverted"
            :solo="!inverted"
            :outlined="outlined"
            :dense="dense"
            :rules="rules"
            :error-messages="errorMessages"
            v-bind="attrs"
            v-on="on"
            :clearable="clearable"
            @click:clear="ClearValue"
          ></v-text-field>
        </template>
        <v-card>

          <v-tabs fixed-tabs v-model="activeTab">

            <v-tab key="calendar"><v-icon size="26" class="mx-1">{{ icons.mdiCalendar }}</v-icon></v-tab>
            <v-tab key="time"><v-icon size="26" class="mx-1">{{ icons.mdiClockOutline }}</v-icon></v-tab>

            <v-tab-item key="calendar">
              <v-date-picker :title-date-format="TitleDateFormat" v-model="workingDate" scrollable></v-date-picker>
            </v-tab-item>

            <v-tab-item key="time">
              <v-time-picker v-model="workingTime" scrollable></v-time-picker>
            </v-tab-item>

          </v-tabs>

          <v-card-actions class="mt-6">
            <v-btn text color="primary" @click="showDialog = false">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="Save" >OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
//import { requiredLookup } from '@/datahelpers/validation'
import { parseJSON, format } from 'date-fns'
import {
  mdiCalendar ,
  mdiClockOutline
} from '@mdi/js'

export default {
    props: {
        value: {
            required: true,
        },
        clearable: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "Pick date & time"
        },
        "errorMessages": {
            type: Array,
            default: function() {
                return [];
            }
        },
        rules: {
            type: Array,
            default: function() {
                return [];
            }
        },
        dense: {
            type: Boolean,
            default: false
        },
        inverted: {
            type: Boolean,
            default: true
        },
        outlined: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            activeTab: 'date',
            showDialog: false,
            workingDate: (this.value ? format(new Date(this.value), "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd")),
            workingTime: (this.value ? format(new Date(this.value), "HH:mm") : format(new Date(), "HH:mm")),
            icons: {
              mdiCalendar,
              mdiClockOutline
            }
        };
    },
    computed: {
        FormattedDate() {
          if (this.value) {
            return format(new Date(this.value), "dd-MM-yyyy HH:mm");
          } return "";
        }
    },
    methods: {
        ClearValue(e) {
          this.workingDate = null;
          this.workingTime = null;
          this.Save(e);
        },
        TitleDateFormat(d) {
          return format(new Date(d), "EEE, do MMM");
        },
        Save(e) {
            let workingDateTime = null;
            if (this.workingDate && this.workingTime) {
              workingDateTime = this.workingDate + "T" + this.workingTime + ":00.00Z";
            }
            this.$emit("input", workingDateTime);
            this.showDialog = false;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
